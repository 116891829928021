import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';

/* Estado */
export const initialState = {
  orientation: window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait',
  isIntroLoaded: false,
  isMainContentLoaded: false,
  isIntroEnded: false,
  isIntroPlaying: false,
  serviceColumnHeight: 'auto',
  showNewsletterEmailRequired: false,
  showNewsletterInvalidEmail: false,
  isMobileFormActive: false,
  form: {
    name: { value: '', validated: false },
    email: { value: '', validated: false },
    company: { value: '', validated: false },
    phone: { value: '', validated: false },
    message: { value: '', validated: false },
    submit: false,
  },
  formSelectedLabel: 'name',
  webpSupport: false,
  heroBgColor: false,
};

/* Reducers */
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case 'changeState':
      return {
        ...state,
        [action.key]: action.value,
      };
    case 'formValues':
      return {
        ...state,
        form: { ...state.form, [action.key]: action.value },
      };
    default:
      return state;
  }
};

const store = configureStore(
  {
    reducer: {
      reducers,
    },
  },
  composeWithDevTools()
);

export default store;
