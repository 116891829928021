const changeState = (key, value) => {
  return {
    type: "changeState",
    key,
    value,
  };
};

const formValues = (key, value) => {
  return {
    type: "formValues",
    key,
    value,
  };
};

export { changeState, formValues };
