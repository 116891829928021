import { lazy, Suspense, useEffect } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
  Route,
  Navigate,
} from 'react-router-dom';
import { changeState } from '../store/actions';
import { isMobile } from 'react-device-detect';
import { isWebpSupported } from 'react-image-webp/dist/utils';

const queryClient = new QueryClient();
const Home = lazy(() => import('../routes/Home'));
const PrivacyPolicy = lazy(() => import('../routes/PrivacyPolicy'));
const Footer = lazy(() => import('../Slides/Footer'));

const Root = () => {
  const isWebPSupported = isWebpSupported();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeState('webpSupport', isWebPSupported));
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={null}>
        <Outlet />
        <Footer />
      </Suspense>
    </QueryClientProvider>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Root />}>
      <Route index element={<Home />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='*' element={<Navigate to='/' replace={true} />} />
    </Route>
  )
);

export default function App() {
  useEffect(() => {
    !isMobile && document.documentElement.classList.add('desktop');
  }, []);

  return <RouterProvider router={router} />;
}
