import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import App from './Router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import vhCheck from 'vh-check';
import './styles/main.scss';

library.add(faChevronDown, fab);

const helmetContext = {};

class WebAPP {
  init() {
    vhCheck();
    this.scrollToTopOnReload();
    ReactDOM.createRoot(document.getElementById('root')).render(
      <Provider store={store}>
        <HelmetProvider context={helmetContext}>
          <App />
        </HelmetProvider>
      </Provider>
    );
  }
  scrollToTopOnReload() {
    'scrollRestoration' in window.history && (window.history.scrollRestoration = 'manual');
  }
}

const epica = new WebAPP();
epica.init();
